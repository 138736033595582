import { Component, Inject, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Store } from '@ngrx/store';
import { AuthService } from '../auth.service';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { loginUser } from '../../state/user/user.actions';

@Component({
  selector: 'app-idp',
  standalone: true,
  imports: [],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss'
})
export class SigninComponent implements OnInit {
  loginType: string | null | undefined;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private oauthService: OAuthService,
    private store: Store,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.loginType = sessionStorage.getItem('loginType');
    if (this.loginType === this.appConfig.idps.cornelsen) {
      this.loginCornelsen();
    } else if (this.loginType === this.appConfig.idps.azure) {
      this.loginAzureAD();
    }
  }

  loginCornelsen() {
    this.oauthService.configure(this.authService.cornelsenIDPConfiguration);
    this.oauthService.loadDiscoveryDocument();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tryLoginCodeFlow().then(() => {
      this.store.dispatch(loginUser());
    });
  }

  loginAzureAD() {
    this.oauthService.configure(this.authService.azureADConfiguration);
    this.oauthService.loadDiscoveryDocument();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tryLoginCodeFlow().then(() => {
      this.store.dispatch(loginUser());
    });
  }
}
