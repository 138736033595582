import { Inject, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}

  get cornelsenIDPConfiguration() {
    return new AuthConfig({
      issuer: `${this.appConfig.cornelsenIdp}`,
      redirectUri: `${this.appConfig.moduleUrl}${this.appConfig.signinPath}`,
      clientId: `${this.appConfig.cornelsenIdpClientId}`,
      postLogoutRedirectUri: `${this.appConfig.portalUrl}${this.appConfig.portalLogoutPath}`,
      responseType: 'code',
      timeoutFactor: this.appConfig.tokenRefreshFactor,
      tokenEndpoint: `${this.appConfig.cornelsenIdp}/oxauth/restv1/token`,
      scope: 'openid profile email roles',
      showDebugInformation: true
    });
  }

  get azureADConfiguration() {
    return new AuthConfig({
      issuer: `${this.appConfig.domain}/${this.appConfig.tenantId}/v2.0`,
      redirectUri: `${this.appConfig.moduleUrl}${this.appConfig.signinPath}`,
      clientId: `${this.appConfig.clientId}`,
      postLogoutRedirectUri: `${this.appConfig.portalUrl}${this.appConfig.portalLogoutPath}`,
      responseType: 'code',
      timeoutFactor: this.appConfig.tokenRefreshFactor,
      strictDiscoveryDocumentValidation: false,
      tokenEndpoint: `${this.appConfig.domain}/${this.appConfig.tenantId}/oauth2/v2.0/token`,
      scope: 'openid profile email',
      showDebugInformation: true
    });
  }

  get securedHosts() {
    return this.appConfig.securedHosts;
  }

  get protectedResources() {
    return {
      endpoint: `${this.appConfig.portalBackendUrl}`,
      scopes: [`${this.appConfig.clientId}/.default`]
    };
  }

  get isLoggedIn() {
    return this.oauthService.hasValidAccessToken();
  }

  loginOnPortal() {
    window.location.href = `${this.appConfig.portalUrl}${this.appConfig.portalLoginPath}?redirectUrl=${encodeURIComponent(this.appConfig.moduleUrl + this.appConfig.accessPath)}`;
  }

  logout() {
    window.location.href = `${this.appConfig.portalUrl}${this.appConfig.portalLogoutPath}`;
  }

  logoutFromModule() {
    this.oauthService.logOut(true);
    const intervalId = setInterval(() => {
      if (!this.oauthService.hasValidAccessToken()) {
        clearInterval(intervalId);
        window.parent.postMessage('logoutComplete', '*');
      }
    }, 100);
  }

  getloginType() {
    return sessionStorage.getItem('loginType');
  }

  initService() {
    const loginType = this.getloginType();
    if (loginType === this.appConfig.idps.cornelsen) {
      this.setLoginCornelsen();
    } else if (loginType === this.appConfig.idps.azure) {
      this.setLoginAzureAD();
    }
  }

  setLoginCornelsen() {
    this.oauthService.configure(this.cornelsenIDPConfiguration);
  }

  setLoginAzureAD() {
    this.oauthService.configure(this.azureADConfiguration);
  }
}
