import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarData, defaultConfig } from './snackbar-data.models';

/**
 * Service to display a **snackbar**.
 * Call **open** and pass the *SnackbarData* for configuration.
 *
 * @example
 * this.snackbarService.open({
 *  message: 'This snackbar closes only on button click',
 *  actionLabel: 'Click to close'
 * });
 *
 * @example
 * this.snackbarService.open({
 *  message: 'This snackbar closes after 5 seconds',
 *  duration: 5000
 * });
 */
@Injectable({
  providedIn: 'root'
})
export class Snackbar {
  private static readonly DURATION_INFINITE = 0;

  private defaultConfig: SnackbarData;

  constructor(private snackBar: MatSnackBar) {
    this.defaultConfig = defaultConfig;
  }

  open(snackbarData: SnackbarData): Observable<boolean> {
    const data = { ...this.defaultConfig, ...snackbarData };

    const duration =
      snackbarData.duration ??
      (!snackbarData.actionLabel ? data.duration : Snackbar.DURATION_INFINITE);

    const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
      duration,
      horizontalPosition: 'center',
      data
    });
    return snackBarRef.afterDismissed().pipe(
      take(1),
      map(action => action.dismissedByAction)
    );
  }
}
