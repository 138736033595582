import { isDevMode } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { userReducer } from './user/user.reducer';
import { UserState } from './user/user.state.model';

export interface AppState {
  user: UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: userReducer
};

export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [] : [];
