<div class="snackbar-content">
  <cornelsen-icon [icon]="icon" variant="flat"></cornelsen-icon>
  <p class="cv-snackbar-message">{{ data.message }}</p>
  <div class="cv-snackbar-action">
    @if (data.actionLabel) {
      <button (click)="matSnackbarRef.dismissWithAction()">
        {{ data.actionLabel }}
      </button>
    }
  </div>
</div>
