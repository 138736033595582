<div class="header">
  <a class="logo" [attr.href]="homeButtonLink">
    <img src="assets/icons/CornelsenLogo.svg" alt="" />
    <div class="logo-title">
      {{ title || 'Cornelsen' }}
    </div>
  </a>

  <div class="header-icons">
    <cv-avatar></cv-avatar>
  </div>
</div>
