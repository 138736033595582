import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private authService: AuthService
  ) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    // currently, we use the idToken to authenticate the user
    // but we also need the access token to be able to decode the
    // idToken on the server side if using the Cornelsen IDP
    const accessToken = this.oauthService.getAccessToken();
    const idToken = this.oauthService.getIdToken();
    if (idToken && this.isSecuredHost(request.url)) {
      // Clone the request and attach the token
      const authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
          AccessToken: accessToken
        }
      });

      return next.handle(authReq);
    }

    // If there is no token, pass the original request
    return next.handle(request);
  }

  private isSecuredHost(url: string) {
    const root = new URL(url).protocol + '//' + new URL(url).host;
    return this.authService.securedHosts.includes(root);
  }
}
