import { createAction, props } from '@ngrx/store';
import { User } from './user.model';

export const setUserData = createAction(
  '[User] Set user name',
  props<{ user: User }>()
);

export const loginUser = createAction('[User] Login');

export const logoutUser = createAction('[User] Logout');
