import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from './user.service';
import { map, mergeMap } from 'rxjs/operators';
import { loginUser, logoutUser, setUserData } from './user.actions';
import { Router } from '@angular/router';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginUser),
      mergeMap(() =>
        this.userService.getUserInfo().pipe(
          map(user => {
            this.router.navigate([`/`]);
            return setUserData({ user: user });
          })
        )
      )
    );
  });

  logoutUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutUser),
        map(() => {
          this.router.navigate([`/`]);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router
  ) {}
}
