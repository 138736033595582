import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cornelsen-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class IconComponent {
  /**
   * Adjusts the size of the icon.
   */
  @Input() size: 's' | 'm' | 'l' | 'xl' = 'm'; // as for version 2.2.0 of uni-icons: s, m, l, xl

  /**
   * Changes icon style.
   */
  @Input() variant: 'outline' | 'flat' = 'outline';

  /**
   * Exact name of the icon as provided by mapping of cv-uni icons.
   */
  @Input() icon: string = '';
}
