<form [formGroup]="formGroup" class="container" (ngSubmit)="onSubmit()">
  <div>
    <div class="sidebar-header">
      <a class="back-link" [routerLink]="['/']"> KI Lösungsassistent </a>
    </div>

    <fieldset
      class="input-container"
      [class.error]="f['file'].invalid && isSubmitted">
      <legend>Schritt 1: Dokument hochladen</legend>
      <app-dnd
        [supportedFormats]="'.pdf,.docx,.txt'"
        (fileUploaded)="onFileUploaded($event)"></app-dnd>
    </fieldset>

    <fieldset
      class="input-container"
      [class.error]="f['taskDescription'].invalid && isSubmitted">
      <legend>Schritt 2: Aufgabenbeschreibung</legend>
      <textarea
        matInput
        formControlName="taskDescription"
        rows="5"
        cols="40"
        placeholder="Beschreiben Sie die Aufgabe."></textarea>
      @if (f['taskDescription'].invalid && isSubmitted) {
        <span class="error-message">{{ taskDescriptionError }}</span>
      }
    </fieldset>
  </div>

  <div class="confirm-button-wrapper">
    <button
      cornelsen-button
      icon="Hinzufuegen"
      content="icon-start"
      type="submit"
      [disabled]="loadingSolution">
      Lösung erstellen
    </button>
  </div>
</form>
