import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';

@Component({
  selector: 'app-dnd',
  standalone: true,
  imports: [DragAndDropDirective, IconComponent],
  templateUrl: './drag-and-drop-upload.component.html',
  styleUrl: './drag-and-drop-upload.component.scss'
})
export class DragAndDropUploadComponent {
  @Input() title: string = 'File upload';
  @Input() description: string =
    'Drag and drop a document or click to search your computer (max. 10MB)';
  @Input() supportedFormats: string = '.pdf';
  @Output() fileUploaded = new EventEmitter<File | null>();

  uploadedFile: File | null = null;
  uploadedFileName = '';

  get isFileUploaded() {
    return this.uploadedFile != null;
  }

  onFileUploaded(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.uploadedFile = inputElement.files[0] as File;
      this.uploadedFileName = this.uploadedFile.name;

      this.fileUploaded.emit(this.uploadedFile);
    }
  }

  onFileDropped(event: File) {
    const supportedFormatsList = this.supportedFormats.split(',');
    const fileName = event.name;
    const fileFormat = fileName.substring(fileName.lastIndexOf('.'));
    if (!supportedFormatsList.includes(fileFormat)) {
      return;
    }

    this.uploadedFile = event;
    this.uploadedFileName = this.uploadedFile.name;

    this.fileUploaded.emit(this.uploadedFile);
  }

  removeFile() {
    this.uploadedFile = null;
    this.uploadedFileName = '';

    this.fileUploaded.emit(null);
  }
}
