import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state.model';

// Selector to get the user state
export const selectUserFeature = createFeatureSelector<UserState>('user');

// Selector to get the user name
export const getFullUserName = createSelector(
  selectUserFeature,
  (userState: UserState) => userState.user?.name ?? ''
);

// Selector to get the user initials
export const getUserInitials = createSelector(
  selectUserFeature,
  (userState: UserState) => {
    if (!userState.user?.name) return '';

    // Splitting the userName string into last name and first name and reversing the order
    let namesSplit = userState
      .user!.name.split(',')
      .map(s => s.trim())
      .reverse();
    // Cornelsen-IDP does simply store "Firstname Lastname" as userName:
    if (!userState.user?.name.includes(',')) {
      namesSplit = userState.user?.name.split(' ').map(s => s.trim());
    }

    // Extracting the initials
    let initials = '';
    namesSplit.forEach(name => {
      if (name.length > 0) {
        initials += name.charAt(0).toUpperCase();
      }
    });

    return initials;
  }
);
