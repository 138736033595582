import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './user.model';
import { APP_CONFIG, AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}

  getUserInfo() {
    const url = `${this.appConfig.portalBackendUrl}/user`;
    return this.http.get<User>(url);
  }
}
