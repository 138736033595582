import { bootstrapApplication } from '@angular/platform-browser';
import { APP_CONFIG, appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';

fetch('environments/config.json')
  .then(response => response.json())
  .then(config => {
    if (config.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, {
      ...appConfig,
      providers: [
        ...appConfig.providers,
        { provide: APP_CONFIG, useValue: config }
      ]
    }).catch(err => console.error(err));
  });
