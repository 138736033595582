<span
  class="cv-common-button cv-common-button--{{ size }} cv-common-button--{{
    appearance
  }} cv-common-button--{{ content }}">
  <cornelsen-icon
    *ngIf="hasIcon"
    [icon]="icon"
    [variant]="iconVariant"></cornelsen-icon>
  <span *ngIf="shouldShowContent" class="cv-common-button-content"
    ><ng-content></ng-content
  ></span>
</span>
