<div class="container">
  @if (message || loadingSolution || errorMessage) {
    <div class="messages">
      <app-message
        [message]="message"
        [loadingSolution]="loadingSolution"
        [errorMessage]="errorMessage"></app-message>
    </div>
  } @else {
    <div class="placeholder-container">
      <h1>Es wurden noch keine Lösungen erstellt</h1>
      <p>
        Fülle das Formular aus und klicke auf "Lösung erstellen" um loszulegen!
      </p>
    </div>
  }

  <div class="disclaimer">
    <small>
      Bitte beachten Sie, dass sich die KI-Werkstatt derzeit in der Testphase
      befindet. Dies bedeutet, dass das Produkt noch in der Entwicklung ist und
      Fehler oder Ungenauigkeiten aufweisen kann. Geben Sie keine vertraulichen
      oder personenbezogenen Informationen ein. Weiteres entnehmen Sie bitte der
      <a href="https://www.cornelsen.de/datenschutz" target="_blank">
        Datenschutzerklärung </a
      >.
    </small>
  </div>
</div>
