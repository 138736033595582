import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ChatMessageState } from '../../models/enums';
import { ChatMessage } from '../../models/models';
import { Snackbar } from '../../shared/components/snackbar/snackbar.service';
import { MatomoService } from '../../services/matomo.service';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    CommonModule,
    ClipboardModule,
    LoaderComponent,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule
  ],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent {
  @Input()
  message: ChatMessage | null = null;

  @Input()
  loadingSolution: boolean = false;

  @Input() errorMessage: string = '';

  currentlyEditing = false;
  messageEditValue: string = '';

  get isDone(): boolean {
    return (
      this.message?.state === ChatMessageState.DONE ||
      this.message?.state === ChatMessageState.MODIFIED
    );
  }

  get isModified(): boolean {
    return this.message?.state === ChatMessageState.MODIFIED;
  }

  get pageNumbers(): number {
    return this.message?.metadata?.pages.length ?? 0;
  }

  get page(): string {
    return this.message?.metadata?.pages.join(', ') ?? '';
  }

  get source(): string {
    return this.message?.metadata?.source ?? '';
  }

  constructor(
    private snackBar: Snackbar,
    private matomoService: MatomoService
  ) {}

  onEdit() {
    this.messageEditValue = this.message?.solution.trim() ?? '';
    this.currentlyEditing = true;
  }

  onEditCancel() {
    this.currentlyEditing = false;
    this.messageEditValue = '';
  }

  onSave() {
    this.currentlyEditing = false;
    if (
      !this.messageEditValue ||
      this.messageEditValue === this.message?.solution
    )
      return;

    if (this.message) {
      this.message = {
        ...this.message,
        state: ChatMessageState.MODIFIED,
        solution: this.messageEditValue
      };
    }

    this.matomoService.trackMessageEdit();
  }

  onCopySuccess() {
    this.snackBar.open({
      message: 'Kopieren erfolgreich',
      type: 'success',
      duration: 3000
    });

    this.matomoService.trackMessageCopy();
  }
}
