import { Component } from '@angular/core';
import { HeaderComponent } from '../shared/components/header/header.component';
import { ChatComponent } from '../chat/chat.component';
import { PromptFormComponent } from '../prompt-form/prompt-form.component';
import { SolverAssistantService } from '../services/solver-assistant.service';
import * as models from '../models/models';
import { ChatMessageState } from '../models/enums';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'app-solver-assistant',
  standalone: true,
  imports: [
    HeaderComponent,
    ChatComponent,
    PromptFormComponent,
    MatSidenavModule
  ],
  templateUrl: './solver-assistant.component.html',
  styleUrl: './solver-assistant.component.scss'
})
export class SolverAssistantComponent {
  message: models.ChatMessage | null = null;

  loadingSolution = false;
  prompt: models.Prompt | null = null;
  fileData: models.FileData | null = null;

  errorMessage: string = '';

  constructor(private solverAssistantService: SolverAssistantService) {}

  public generateSolution(prompt: models.Prompt) {
    this.loadingSolution = true;
    this.prompt = prompt;
    this.errorMessage = '';

    if (this.shouldUploadFile(prompt.file)) {
      this.uploadFileAndGenerateSolution(prompt);
    } else {
      const fileData = prompt.file ? this.fileData : null;
      const request = this.createPromptRequest(fileData, prompt);
      this.getSolution(request);
    }
  }

  uploadFileAndGenerateSolution(prompt: models.Prompt): void {
    this.solverAssistantService.uploadFile(prompt.file).subscribe({
      next: (resp: models.FileData) => {
        this.fileData = {
          ...resp,
          lastModifiedDate: prompt.file.lastModified
        };
        const request = this.createPromptRequest(resp, prompt);
        this.getSolution(request);
      },
      error: () => {
        this.loadingSolution = false;
        this.errorMessage = 'Failed to fetch data.';
      }
    });
  }

  getSolution(request: models.PromptRequest): void {
    this.solverAssistantService.getSolution(request).subscribe({
      next: (resp: models.ChatMessage) => {
        this.message = {
          ...resp,
          state: ChatMessageState.DONE,
          date: new Date()
        };
        this.loadingSolution = false;
      },
      error: err => {
        // if 404, file does not exist in db, so it needs to be uploaded
        if (err.status === 404) {
          this.uploadFileAndGenerateSolution(this.prompt!);
        } else {
          this.loadingSolution = false;
          this.errorMessage = 'Failed to fetch data.';
        }
      }
    });
  }

  createPromptRequest(
    fileData: models.FileData | null,
    prompt: models.Prompt
  ): models.PromptRequest {
    let request: models.PromptRequest = {
      task_text: prompt.taskDescription
    };

    if (fileData) {
      request = {
        ...request,
        document_id: fileData.document_id
      };
    }
    return request;
  }

  shouldUploadFile(file: File): boolean {
    if (file) {
      if (!this.fileData) {
        return true;
      } else if (
        this.fileData.filename !== file.name ||
        this.fileData.lastModifiedDate !== file.lastModified
      ) {
        return true;
      }
    }

    return false;
  }
}
