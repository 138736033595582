import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class LoaderComponent {
  /**
   * Adjusts the maximum size of the loader.
   */
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
