export type SnackbarData = {
  message: string;
  type?: 'success' | 'warning' | 'neutral';
  actionLabel?: string;
  duration?: number;
};

export const defaultConfig: SnackbarData = {
  message: '',
  type: 'neutral',
  actionLabel: 'Schließen'
};
