<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div
  class="avatar user"
  [matMenuTriggerFor]="menu"
  [matTooltip]="(userName$ | async) ?? ''"
  matTooltipClass="prompt-tooltip"
  matTooltipShowDelay="1000">
  {{ userInitials$ | async }}
</div>

<mat-menu #menu="matMenu" class="dropdown-menu">
  <ng-template matMenuContent>
    <div class="logout-item menu-item" (click)="logout()">
      <cornelsen-icon icon="Login"></cornelsen-icon>Abmelden
    </div>
  </ng-template>
</mat-menu>
