import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { loginUser } from './state/user/user.actions';
import { AuthService } from './auth/auth.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.authService.initService();
      this.store.dispatch(loginUser());
    }
  }

  constructor(
    private authService: AuthService,
    private store: Store
  ) {}
}
