import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';

enum MatomoEventCategory {
  Prompt = 'Prompt',
  ChatInteraction = 'Chat interaction'
}

enum MatomoEventAction {
  PromptSent = 'Prompt sent',
  MessageCopied = 'Message copied',
  MessageEdited = 'Message edited'
}

@Injectable({
  providedIn: 'root'
})
export class MatomoService {
  constructor(private matomoTracker: MatomoTracker) {}

  trackPromptSent(prompt: string, file: string) {
    this.matomoTracker.trackEvent(
      MatomoEventCategory.Prompt,
      MatomoEventAction.PromptSent,
      `Prompt: ${prompt}, File: ${file}`
    );
  }

  trackMessageEdit() {
    this.matomoTracker.trackEvent(
      MatomoEventCategory.ChatInteraction,
      MatomoEventAction.MessageEdited
    );
  }

  trackMessageCopy() {
    this.matomoTracker.trackEvent(
      MatomoEventCategory.ChatInteraction,
      MatomoEventAction.MessageCopied
    );
  }
}
