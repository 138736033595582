import { createReducer, on } from '@ngrx/store';
import { logoutUser, setUserData } from './user.actions';
import { UserState } from './user.state.model';

export const initialState: UserState = {
  user: null,
  error: null
};

export const userReducer = createReducer(
  initialState,
  on(setUserData, (state, { user }) => ({
    ...state,
    user: user,
    loading: false,
    error: null
  })),
  on(logoutUser, () => initialState)
);
