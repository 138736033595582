import { Routes } from '@angular/router';
import { SolverAssistantComponent } from './solver-assistant/solver-assistant.component';
import { AuthGuard } from './auth/auth.guard';
import { AccessComponent } from './auth/access/access.component';
import { SigninComponent } from './auth/signin/signin.component';
import { LogoutComponent } from './auth/logout/logout.component';

export const routes: Routes = [
  {
    path: '',
    component: SolverAssistantComponent,
    canActivate: [AuthGuard]
  },
  { path: 'access', component: AccessComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '**', redirectTo: '', canActivate: [AuthGuard] }
];
