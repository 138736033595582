import { Component, Input } from '@angular/core';
import { ChatMessage } from '../models/models';
import { MessageComponent } from './message/message.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [MessageComponent],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  @Input() message: ChatMessage | null = null;

  @Input() loadingSolution: boolean = false;

  @Input() errorMessage: string = '';
}
