import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {}

  getMatomoConfig() {
    return {
      siteId: this.appConfig.matomoSiteId,
      trackerUrl: this.appConfig.matomoTrackerUrl
    };
  }
}
