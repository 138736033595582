import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '../models/models';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class SolverAssistantService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private httpClient: HttpClient
  ) {}

  uploadFile(file: File): Observable<models.FileData> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<models.FileData>(
      `${this.appConfig.aiBackendUrl}/upload-file/`,
      formData,
      {
        responseType: 'json'
      }
    );
  }

  getSolution(request: models.PromptRequest): Observable<models.ChatMessage> {
    return this.httpClient.post<models.ChatMessage>(
      `${this.appConfig.aiBackendUrl}/generate-solution`,
      request,
      { responseType: 'json' }
    );
  }
}
