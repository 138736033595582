import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[cornelsen-button], button[cornelsen-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [IconComponent, CommonModule]
})
export class ButtonComponent {
  @Input() appearance: 'primary' | 'secondary' | 'free' | 'outline' = 'primary';
  @Input() content: 'text-only' | 'icon-start' | 'icon-end' | 'icon-only' =
    'text-only';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() icon = '';
  @Input() iconVariant: 'outline' | 'flat' = 'outline';

  get hasIcon(): boolean {
    return this.content !== 'text-only' && !!this.icon;
  }

  get shouldShowContent(): boolean {
    return this.content !== 'icon-only';
  }
}
