<div class="container">
  <mat-drawer-container class="side-container">
    <mat-drawer class="drawer-content" mode="side" opened>
      <div drawer>
        <app-prompt-form
          (submittedPrompt)="generateSolution($event)"
          [loadingSolution]="loadingSolution"></app-prompt-form>
      </div>
    </mat-drawer>

    <mat-drawer-content class="main-content">
      <app-chat
        [message]="message"
        [loadingSolution]="loadingSolution"
        [errorMessage]="errorMessage"></app-chat>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
