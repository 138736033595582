import { ApplicationConfig, inject, InjectionToken } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { AuthInterceptor } from './auth/auth.interceptor';
import { OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { metaReducers, reducers } from './state';
import { provideEffects } from '@ngrx/effects';
import { UserEffects } from './state/user/user.effects';
import { ConfigService } from './services/config.service';
import { provideMatomo, withRouter } from 'ngx-matomo-client';

export class AppConfig {
  production!: boolean;
  moduleUrl!: string;
  accessPath!: string;
  signinPath!: string;
  aiBackendUrl!: string;
  clientId!: string;
  tenantId!: string;
  instance!: string;
  domain!: string;
  cornelsenIdp!: string;
  cornelsenIdpClientId!: string;
  idps!: {
    cornelsen: string;
    azure: string;
  };
  portalUrl!: string;
  portalLoginPath!: string;
  portalLogoutPath!: string;
  portalBackendUrl!: string;
  matomoSiteId!: string;
  matomoTrackerUrl!: string;
  securedHosts!: string[];
  tokenRefreshFactor!: number;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore(reducers, { metaReducers }),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideOAuthClient(),
    provideEffects([UserEffects]),
    {
      provide: OAuthStorage,
      useValue: localStorage
    },
    provideMatomo(() => inject(ConfigService).getMatomoConfig(), withRouter())
  ]
};
