import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './access.component.html',
  styleUrl: './access.component.scss'
})
export class AccessComponent implements OnInit {
  idp: string = '';

  constructor(
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.idp = params['idp'];
    });
    if (this.idp === 'cornelsen') {
      this.oauthService.configure(this.authService.cornelsenIDPConfiguration);
    } else {
      this.oauthService.configure(this.authService.azureADConfiguration);
    }
    this.oauthService.loadDiscoveryDocument();

    sessionStorage.setItem('loginType', this.idp);

    this.oauthService.initCodeFlow();
  }
}
