<div appDnd class="container" (fileDropped)="onFileDropped($event)">
  <div class="upload-button-container">
    <input
      type="file"
      [accept]="supportedFormats"
      #fileUpload
      (change)="onFileUploaded($event)" />
    <button type="button" (click)="fileUpload.click()" class="upload-button">
      <cornelsen-icon icon="Pfeil_nach_oben"></cornelsen-icon>
    </button>
  </div>
  <div class="content">
    <div class="title">
      {{ isFileUploaded ? uploadedFileName : title }}
    </div>
    <div class="description">
      {{ isFileUploaded ? 'Drag and drop to replace document' : description }}
    </div>
  </div>
  @if (isFileUploaded) {
    <button type="button" class="remove-button" (click)="removeFile()">
      <cornelsen-icon icon="Loeschen"></cornelsen-icon>
    </button>
  }
</div>
