import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { DragAndDropUploadComponent } from '../shared/components/drag-and-drop-upload/drag-and-drop-upload.component';
import { ButtonComponent } from '../shared/components/button/button.component';
import { IconComponent } from '../shared/components/icon/icon.component';
import { RouterModule } from '@angular/router';
import * as models from '../models/models';
import { MatomoService } from '../services/matomo.service';

@Component({
  selector: 'app-prompt-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    IconComponent,
    ReactiveFormsModule,
    ButtonComponent,
    RouterModule,
    DragAndDropUploadComponent
  ],
  templateUrl: './prompt-form.component.html',
  styleUrl: './prompt-form.component.scss'
})
export class PromptFormComponent {
  @Input() loadingSolution = false;

  @Output() submittedPrompt: EventEmitter<models.Prompt> = new EventEmitter();

  isSubmitted = false;
  formGroup: FormGroup = this.formBuilder.group({
    file: [null, []],
    taskDescription: ['', [Validators.required, Validators.minLength(2)]]
  });

  currentlyUploadedFile: File | null = null;

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  get taskDescriptionError(): string {
    return 'Bitte geben Sie mindestens 2 Zeichen ein.';
  }

  constructor(
    private formBuilder: FormBuilder,
    private matomoService: MatomoService
  ) {}

  onSubmit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const prompt = {
      taskDescription: this.f['taskDescription'].value as string,
      file: this.f['file'].value as File
    };

    this.matomoService.trackPromptSent(
      prompt.taskDescription,
      prompt.file?.name ?? ''
    );
    this.submittedPrompt.emit(prompt);
  }

  onFileUploaded(file: File | null): void {
    this.currentlyUploadedFile = file;
    this.formGroup.patchValue({ file: file });
  }
}
