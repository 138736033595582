import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from '../../icon/icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../../../../auth/auth.service';
import { logoutUser } from '../../../../state/user/user.actions';
import {
  getFullUserName,
  getUserInitials
} from '../../../../state/user/user.selectors';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cv-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatTooltipModule, IconComponent]
})
export class AvatarComponent implements OnInit {
  userInitials$: Observable<string> = of('');
  userName$: Observable<string> = of('');

  constructor(
    private store: Store,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userInitials$ = this.store.select(getUserInitials);
    this.userName$ = this.store.select(getFullUserName);
  }

  logout() {
    this.store.dispatch(logoutUser());
    this.authService.logout();
  }
}
