import { Component, Inject, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from './avatar/avatar.component';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, AvatarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() title: string = '';
  homeButtonLink: string = '';

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
    this.homeButtonLink = this.config.portalUrl;
  }
}
