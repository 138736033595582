import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { IconComponent } from '../icon/icon.component';
import { SnackbarData } from './snackbar-data.models';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [MatSnackBarModule, IconComponent],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss'
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    public matSnackbarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  get icon(): string {
    switch (this.data.type) {
      case 'success':
        return 'Checkmark';
      case 'warning':
        return 'Stolperstelle_Schild';
      default:
        return '';
    }
  }
}
