<div class="message-avatar">
  <img src="assets/icons/avatar.svg" alt="" />
</div>
<article class="message-card">
  @if (loadingSolution) {
    <app-loader></app-loader>
  } @else {
    <div class="message-card-header">
      <time>{{ message?.date | date: 'HH:mm' }}</time>
      <h2 class="message-card-title">Lösungsvorschlag</h2>
    </div>
    <div class="message-card-main">
      @if (!currentlyEditing && !errorMessage) {
        <p>{{ message?.solution }}</p>
      } @else if (errorMessage) {
        <p>{{ errorMessage }}</p>
      } @else {
        <mat-form-field>
          <textarea cdkTextareaAutosize [(ngModel)]="messageEditValue" matInput>
            {{ message?.solution }}
          </textarea>
        </mat-form-field>
      }
    </div>
    <div
      class="message-card-footer"
      [ngClass]="{ 'border-edit-mode': currentlyEditing }">
      <div class="chip-container">
        @if (isDone && message?.metadata) {
          <div class="chip">
            {{ pageNumbers > 1 ? 'Pages:' : 'Page:' }}
            {{ page }}
          </div>
          <div class="chip">Source: {{ source }}</div>
        }
        @if (isModified) {
          <div class="chip">Editiert</div>
        }
      </div>

      @if (isDone) {
        <div class="action-container">
          @if (!currentlyEditing) {
            <button
              cornelsen-button
              appearance="free"
              icon="Schreiben"
              content="icon-start"
              (click)="onEdit()">
              Verbessern
            </button>
            <button
              cornelsen-button
              icon="Kopieren"
              content="icon-start"
              appearance="free"
              [cdkCopyToClipboard]="message?.solution ?? ''"
              [cdkCopyToClipboardAttempts]="3"
              (cdkCopyToClipboardCopied)="onCopySuccess()">
              Kopieren
            </button>
          } @else {
            <button
              cornelsen-button
              appearance="free"
              icon="Schliessen"
              content="icon-start"
              (click)="onEditCancel()">
              Abbrechen
            </button>
            <button
              cornelsen-button
              appearance="free"
              icon="Speichern"
              content="icon-start"
              (click)="onSave()">
              Änderungen speichern
            </button>
          }
        </div>
      }
    </div>
  }
</article>
